import axios from "axios";
import config from "../config/equipos.config";

const traerTodosLosDispositivos = () => axios.get(`${config.host}/todos`);
const traerDispositivosPorAreasSinPaginar = (id) =>
  axios.get(`${config.host}/v2/areas/${id}/${"?soloDispositivos=1"}`);
const traerDispositivosPorAreas = (
  currentPage,
  perPage,
  departamentoId,
  ciudadId,
  sedeId,
  areaId,
  query
) => {
  const pagination = `perPage=${perPage}&currentPage=${currentPage}`;
  const ubicacion = `departamentoId=${departamentoId}&ciudadId=${ciudadId}&sedeId=${sedeId}&areaId=${areaId}`;
  return axios.get(
    `${
      config.host
    }/v2/todos/${"?soloDispositivos=1"}&${pagination}&${ubicacion}&query=${query}`
  );
};
const traerDispositivoPorId = (id) =>
  axios.get(
    `${
      config.host
    }/v2/dispositivos/${id}/${"?sensorTipo=1&periodo=1&periodoFecha=2022-01-01"}`
  );

export default {
  traerTodosLosDispositivos,
  traerDispositivosPorAreas,
  traerDispositivosPorAreasSinPaginar,
  traerDispositivoPorId,
};
